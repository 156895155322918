import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import { SocketService } from 'services/socket.service';
import { environment } from 'environment';

import { ListarNotificacionesResponse } from 'interfaces/HttpResponses.interface';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public message: Subject<{action:string, message:string }>;
  public estado: Subject<string>;

  constructor(private wsService: SocketService, private http: HttpClient,
    private messageService: MessageService) {
    this.message =  this.wsService.connect(environment.APIS.WS_URL);
    this.estado  =  this.wsService.connectionState;
  }

  /**
   * Obtiene las notificaciones asociadas a un RUT
   * @param datos
   * @returns
   */
  getListadoNotificacion(datos: any) {
    const body = JSON.stringify(datos);
    return this.http.post<ListarNotificacionesResponse>(`${environment.APIS.CENTRO_NOTIFICACIONES}/listar`, body, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

  /**
   * Obtiene las notificaciones sin leer
   * @param usuarioId
   * @returns
   */
  getListadoNotificacionTotal(usuarioId: string): Observable<any> {
    return this.http.get(`${environment.APIS.CENTRO_NOTIFICACIONES}/count/${usuarioId}`);
  }

  /**
   * Obtiene una URL firmada para la descarga de archivos en S3
   * @param datos
   * @returns
   */
  getSignedURL(datos: any): Observable<any> {
    const body = JSON.stringify(datos);
    return this.http.post(`${environment.APIS.CENTRO_NOTIFICACIONES}/generar-url-firmada`, body, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }

  /**
   * Marca una notificación como leida
   * @param datos
   * @returns
   */
  setNotificacionLeida(datos: any): Observable<any> {
    const body = JSON.stringify(datos);
    return this.http.post(`${environment.APIS.CENTRO_NOTIFICACIONES}/marcar-leido`, body, {
      headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    });
  }
  /**
   * Agrega un nuevo mensaje
   * @param severity
   * @param summary
   * @param detail
   * @returns
   */
  addToast(severity: string, summary: string, detail: string) {
    this.messageService.add({severity: severity, summary: summary, detail: detail});
  }
}
