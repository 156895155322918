import {Injectable} from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { environment } from 'environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private subject?: WebSocketSubject<{ action: string, message: string}>;
  private conectadoState: Subject<string> = new Subject();

  constructor() { }

  get websocket(){
    return this.subject?.asObservable()
  }

  get connectionState(){
    return this.conectadoState as Subject<string>;
  }

  /**
   * Genera la conexión hacia un websocket
   * @param url Websocket
   * @returns 
   */
  public connect(url: string) {
    console.log('websocket url', url);
    if (!this.subject) {
      this.subject = webSocket({
        url: url,
        openObserver: {
          next: (e) => {
            this.conectadoState.next('connected');
            console.log('Conexión establecida');
          }
        }
      });

      this.subject.multiplex(() => 'login-info', () => null, () => true)
    }

    return this.subject;
  }
}
